import React from 'react'
import ArbitratorList from '../components/ArbitratorList'

const ArbInfo = () => {
  return (
    <div>
      <ArbitratorList />
    </div>
  )
}

export default ArbInfo